import { Timestamp } from 'firebase-admin/firestore'
import UploadProcessingStepsJson from '../data/UploadProcessingSteps.json'
import { JaguarStyle, JaguarType } from './types.js'
import { UploadedFileInfoMetadata } from '../leopard/UploadedFileInfoForServer.js'

export type AdditionalInfo = {
  PropertyAdditionalInfo: string
  AdditionalInfoType: AdditionalInfoType
}

export type CreatePromptRequestBase = {
  languageCode: string // Language
  style: JaguarStyle // 'Professional'
  type: JaguarType
  logAdInJaguar?: boolean
  keywordMapItems: JaguarKeywordMapItem[]
  additionalInfo: AdditionalInfo[]
  adLength: number
  targetAudience: TargetAudience
  headerAdLength?: number
}

export type CreatePromptRequest = CreatePromptRequestBase & {
  requestId: string
  organizationId?: string
  placesId?: string
  propertyInfo: string // 'Small aparment in Stockholm. Balcony, second floor. View over park'
}

type UpladedFileMetaData = {
  // Used in summarize texts (Upload.tsx)
  filename: string

  // Used in ViteLanding
  checked?: boolean
  caption?: string
}

type UploadMetadata = {
  userId: string
  vitecNextOrderId?: string
}

export type UploadFilesRequest = {
  requestId: string
  languageCode: string // 'en'
  adLength: number
  files: {
    uri: string
    metaData: UpladedFileMetaData
  }[]
  style: JaguarStyle
  metaData: UploadMetadata
}

export enum UploadStatus {
  Processing = 'processing',
  Done = 'done',
  Error = 'error'
}

export type Upload = {
  id: string
  metaData: UploadMetadata
  status: UploadStatus
  infoFromImages: UploadedFileInfo
  createdAt: string
  requestId: string
  files?: {
    uri: string
    metaData: UpladedFileMetaData
  }[]
}

export enum AdditionalInfoType {
  EnergyDeclaration = 'EnergyDeclaration',
  BRFReport = 'BRFReport',
  PropertyDescription = 'PropertyDescription',
  PropertyInspection = 'PropertyInspection',
  Other = 'Other'
}

export type EditSectionRequest = {
  requestId?: string
  organizationId?: string
  languageCode: string // da
  textTransformType: 'rephrase' | 'expand' | 'shorten'
  keyWords?: any[]
  keywordMapItems?: JaguarKeywordMapItem[]
  generatedAd?: string
  propertyInfo?: string
  sectionText: string
}

export type UploadedFileStatus = 'processing' | 'done' | 'error'

export type UploadedFileProcessingStep = keyof typeof UploadProcessingStepsJson

export type UploadedFile = UploadedFileInfo & {
  id: string // 'Energideklaration.pdf_q86zfwj5x.pdf'
  createdAt: Timestamp
  createdBy: string
  processingStep?: UploadedFileProcessingStep
  status: UploadedFileStatus
  error?: string
  metaData?: UploadedFileInfoMetadata
}

export type UploadedFileInfo = {
  AdditionalInfoType?: AdditionalInfoType //'EnergyDeclaration'
  PropertyAdditionalInfo?: string // Can be object in case of Byggemate, '1. Energy class: B\n2. Date of declaration: N'
  Headline?: string
  status: 'processing' | 'done' | 'error'
  error?: string
  processingStep?: UploadedFileProcessingStep
  updatedAt?: string
  updatedBy?: string
  createdAt?: string
  createdBy?: string
}

export enum TargetAudience {
  general = 'general',
  families = 'families',
  DINK = 'DINK',
  retirees = 'retirees',
  singlesWithKids = 'singlesWithKids',
  singles = 'singles'
}

export type JaguarKeywordMapItem = {
  id: string
  localizedName: string
}
